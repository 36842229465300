import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { ListTreeProps } from './models';

import './ListTree.scss';

const ListTree: FC<ListTreeProps> = ({ items, currentLevel, maxLevelToShow, customClass }) => {
  if (maxLevelToShow && currentLevel === maxLevelToShow) {
    return null;
  }

  return (
    <>
      {items.map(({ id, path, title, children }) => (
        <ul key={id} className={classNames('list-tree', customClass)} data-testid="list-tree">
          <li className="list-tree__item">
            <Link className="list-tree__link" to={path}>
              {title}
            </Link>

            {children.length > 0 ? (
              <ListTree
                items={children}
                currentLevel={currentLevel + 1}
                maxLevelToShow={maxLevelToShow}
              />
            ) : null}
          </li>
        </ul>
      ))}
    </>
  );
};

export default ListTree;
