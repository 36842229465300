import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container } from 'layout';
import Layout from 'layout/Layout';
import PageIntro from 'components/elements/PageIntro';
import Typography from 'components/elements/Typography/Typography';
import Sitemap from 'components/sections/Sitemap';

import { IPropsSitemapPage } from './models';

import './SitemapPage.scss';

const SitemapPage: FC<IPropsSitemapPage> = ({ data: { pageData }, pageContext }) => {
  const {
    seo,
    urls,
    excludedPages,
    maxLevelToShow,
    titleSitemap,
    createDate,
    updateDate,
    showBreadcrumbsDesktop,
    showBreadcrumbsMobile,
  } = pageData;
  const { breadcrumbs, lang, url } = pageContext;

  return (
    <Layout
      data-testid="SitemapPage"
      seo={seo}
      lang={lang}
      urls={urls}
      pagePathname={url}
      createDate={createDate}
      updateDate={updateDate}
      className="sitemap-page"
    >
      <PageIntro
        breadcrumbs={breadcrumbs}
        showBreadcrumbsDesktop={showBreadcrumbsDesktop}
        showBreadcrumbsMobile={showBreadcrumbsMobile}
      />
      <Container className="sitemap-page__main-container">
        {titleSitemap?.length ? (
          <Typography data={titleSitemap} customClass="sitemap-page__title" />
        ) : null}
        <Sitemap lang={lang} excludedPages={excludedPages} maxLevelToShow={maxLevelToShow} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String!, $lang: String!) {
    pageData: sitemapPage(url: { eq: $url }, lang: { eq: $lang }) {
      ...FragmentSitemapPage
    }
  }
`;

export default SitemapPage;
