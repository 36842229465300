import React, { FC } from 'react';
import useSiteTree, { DEFAULT_EXCLUDED_PAGES, TreeItem } from 'hooks/useSiteTree';

import ListTree from 'components/elements/ListTree';

import { IPropsSitemap } from './models';

import './Sitemap.scss';

const Sitemap: FC<IPropsSitemap> = ({ lang, excludedPages, maxLevelToShow }) => {
  const data: TreeItem[] = useSiteTree({
    lang,
    exclude: [
      ...DEFAULT_EXCLUDED_PAGES,
      ...(excludedPages?.length ? excludedPages.map((item) => item.url) : []),
    ],
  });

  const OrderByPageType: { [key: string]: TreeItem[] } = {
    LDSPage: [],
    SitemapPage: [],
    ContactUsPage: [],
    rest: [],
  };

  data[0]?.children.forEach((item) => {
    if (item?.pageType && OrderByPageType?.[item.pageType]) {
      OrderByPageType[item.pageType].push(item);
    } else {
      OrderByPageType.rest.push(item);
    }
  });

  return data?.[0] ? (
    <div data-testid="Sitemap" className="sitemap">
      <div className="sitemap__wrapper">
        <div className="sitemap__col">
          <ListTree
            currentLevel={0}
            maxLevelToShow={maxLevelToShow}
            items={[
              {
                id: data[0].id,
                path: data[0].path,
                title: data[0].title,
                children: [],
              },
            ]}
            customClass="sitemap__item"
          />
          {OrderByPageType.rest?.length ? (
            <ListTree
              currentLevel={0}
              maxLevelToShow={maxLevelToShow}
              items={OrderByPageType.rest}
              customClass="sitemap__item"
            />
          ) : null}
        </div>
        <div className="sitemap__col">
          {OrderByPageType.LDSPage?.length ? (
            <ListTree
              currentLevel={0}
              maxLevelToShow={maxLevelToShow}
              items={OrderByPageType.LDSPage}
              customClass="sitemap__item"
            />
          ) : null}

          {OrderByPageType.ContactUsPage?.length ? (
            <ListTree
              currentLevel={0}
              maxLevelToShow={maxLevelToShow}
              items={OrderByPageType.ContactUsPage}
              customClass="sitemap__item"
            />
          ) : null}

          {OrderByPageType.SitemapPage?.length ? (
            <ListTree
              currentLevel={0}
              maxLevelToShow={maxLevelToShow}
              items={OrderByPageType.SitemapPage}
              customClass="sitemap__item"
            />
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
};

export default Sitemap;
