import startCase from 'lodash/startCase';
import trim from 'lodash/trim';

import { DEFAULT_EXCLUDED_PAGES, DEFAULT_HOMEPAGE_PATH, DEFAULT_TITLE_OVERRIDE } from './constants';
import { CreateTreeParams, SiteNode, TitleOverride, TreeItem } from './models';

export const prepareTitle = ({ title, path }: SiteNode, titleOverride: TitleOverride[] = []) => {
  const replacement = titleOverride.find((page) => page.path === path);

  if (replacement) {
    return replacement?.title;
  }

  return title || startCase(trim(path, '/'));
};

export const maxToMinSort = (a: number, b: number) => {
  if (a > b) {
    return -1;
  }

  if (a < b) {
    return 1;
  }

  return 0;
};

export const splitParts = (path: string) => trim(path, '/').split('/');
export const numOfParts = (path: string) => splitParts(path).length;

export const matchChildren = (node: TreeItem, parents: TreeItem[]) => ({
  ...node,
  children: parents
    .filter(({ parentId }) => parentId === node.id)
    .map((child) => matchChildren(child, parents)),
});

export const createTree = ({
  nodes = [],
  exclude = DEFAULT_EXCLUDED_PAGES,
  titleOverride = DEFAULT_TITLE_OVERRIDE,
}: CreateTreeParams): TreeItem[] => {
  const prepareList = nodes
    .map((node) => ({
      ...node,
      title: prepareTitle(node, titleOverride),
      children: [],
    }))
    .filter(({ path }) => !exclude.includes(path))
    .sort((a, b) => maxToMinSort(numOfParts(a.path), numOfParts(b.path)));

  const findParents = prepareList.map((node) => {
    const parts = splitParts(node.path);

    const parentCandidates = [
      ...Array(parts.length)
        .fill(null)
        .map((_, index) => parts.slice(0, index).join('/'))
        .filter(Boolean)
        .sort((a, b) => maxToMinSort(numOfParts(a), numOfParts(b))),
      DEFAULT_HOMEPAGE_PATH,
    ];

    const parentId =
      parentCandidates.map((potentialParent) =>
        prepareList.find(
          ({ path }) => (trim(path, '/') || DEFAULT_HOMEPAGE_PATH) === potentialParent
        )
      )[0]?.id || null;

    return {
      ...node,
      parentId: parentId !== node.id ? parentId : null,
    };
  });

  const tree = findParents
    .filter((item) => item.parentId === null)
    .map((node) => matchChildren(node, findParents));

  return tree;
};
